export type projectAlias = 'dev' | 'proto' | 'prod';
export type localOrCloud = 'local' | 'cloud';

export type ProjectConfig = {
    [key in projectAlias]: {
        firebaseConfig: { [key: string]: string };
        cloudRunURLs: { [key: string]: string };
        algoliaConfig: {
            local: { [key: string]: string };
            cloud: { [key: string]: string };
        };
    };
}

// These are keyed the same as the aliases in .firebasrc , which firebase calls a "project".
const projectConfigs: ProjectConfig = {
    "dev": {
        "firebaseConfig": {
            apiKey: "AIzaSyAJg2nu00eKHxFaGiX3ftIPLpI1bxPPNSo",
            authDomain: "dev.syncretic.cc",
            projectId: "syncretic-prototype-01-dev",
            storageBucket: "syncretic-prototype-01-dev.appspot.com",
            messagingSenderId: "455859974342",
            appId: "1:455859974342:web:e3222c277b22a17ca6a7c7"
        },
        "cloudRunURLs": {
            "runLanguageModel": "https://runlanguagemodel-25oirda6sq-uc.a.run.app/runlanguagemodel",
            "runAgent": "https://runagent-25oirda6sq-uc.a.run.app/",
        },
        "algoliaConfig": {
            local: {
                appId: "O0ZC7T5GL4",
                searchApiKey: "691a3087ebd860db4b468e62b967e423",
                allIndexName: "local_ALL_INDEX",
                entityIndexName: "local_ENTITY_INDEX",
                entityRecordIndexName: "local_ENTITY_RECORDS_INDEX",
            },
            cloud: {
                appId: "O0ZC7T5GL4",
                searchApiKey: "691a3087ebd860db4b468e62b967e423",
                allIndexName: "dev_ALL_INDEX",
                entityIndexName: "dev_ENTITY_INDEX",
                entityRecordIndexName: "dev_ENTITY_RECORDS_INDEX",
            }
        }
    },
    "proto": {
        "firebaseConfig": {
            apiKey: "AIzaSyC_8lGDjHSKFqSqaYGvU5cP4JwZ_xnPTAs",
            authDomain: "prototype.syncretic.cc",
            projectId: "syncretic-prototype-01",
            storageBucket: "syncretic-prototype-01.appspot.com",
            messagingSenderId: "317167905555",
            appId: "1:317167905555:web:5df35becbdd44c176f48e6",
        },
        "cloudRunURLs": {
            "runLanguageModel": "https://runlanguagemodel-2laxbn7j7a-uc.a.run.app/runlanguagemodel",
            "runAgent": "",
        },
        "algoliaConfig": {
            local: {
                appId: "O0ZC7T5GL4",
                searchApiKey: "691a3087ebd860db4b468e62b967e423",
                allIndexName: "local_ALL_INDEX",
                entityIndexName: "local_ENTITY_INDEX",
                entityRecordIndexName: "local_ENTITY_RECORDS_INDEX",
            },
            cloud: {
                appId: "O0ZC7T5GL4",
                searchApiKey: "073b2aa9884b5d0afd9a8aa28431050a",
                allIndexName: "prod_ALL_INDEX",
                entityIndexName: "prod_ENTITY_INDEX",
                entityRecordIndexName: "prod_ENTITY_RECORDS_INDEX",
            }
        }
    },
    "prod": {
        "firebaseConfig": {
            apiKey: "AIzaSyD7F99tT1Pg5rBaw06xY50SjSj0WWeEREQ",
            authDomain: "system.syncretic.cc",
            projectId: "syncretic-system",
            storageBucket: "syncretic-system.appspot.com",
            messagingSenderId: "889882364138",
            appId: "1:889882364138:web:96db37568d25d6bc98af93"
        },
        "cloudRunURLs": {
            "runLanguageModel": "",
            "runAgent": "",
        },
        "algoliaConfig": {
            local: {
                appId: "O0ZC7T5GL4",
                searchApiKey: "691a3087ebd860db4b468e62b967e423",
                allIndexName: "local_ALL_INDEX",
                entityIndexName: "local_ENTITY_INDEX",
                entityRecordIndexName: "local_ENTITY_RECORDS_INDEX",
            },
            cloud: {
                appId: "O0ZC7T5GL4",
                searchApiKey: "073b2aa9884b5d0afd9a8aa28431050a",
                allIndexName: "prod_ALL_INDEX",
                entityIndexName: "prod_ENTITY_INDEX",
                entityRecordIndexName: "prod_ENTITY_RECORDS_INDEX",
            }
        }
    },
};

const firebaseProjectAlias: projectAlias = process.env?.REACT_APP_FIREBASE_PROJECT_ALIAS as projectAlias || 'dev';
const deploymentEnvironment: localOrCloud = process.env?.REACT_APP_DEPLOYMENT_TYPE as localOrCloud || 'cloud';

export const firebaseConfig = projectConfigs[firebaseProjectAlias].firebaseConfig;
export const cloudRunURLs = projectConfigs[firebaseProjectAlias].cloudRunURLs;
export const algoliaConfig = projectConfigs[firebaseProjectAlias].algoliaConfig[deploymentEnvironment];

export const environmentType = process.env?.NODE_ENV || 'development';

console.log(`firebase project alias: ${firebaseProjectAlias}
firebase project id: ${firebaseConfig['projectId']}
environmentType: ${environmentType}
deploymentType: ${deploymentEnvironment}
version: ${process.env?.REACT_APP_VERSION}`);
