import { FC, ReactNode, createContext, useMemo, useState, useEffect, useCallback } from 'react';
import { Spin } from 'antd';
import { useBoundCollection } from '../use/data/useBoundCollection';
import { useBoundDoc } from '../use/data/useBoundDoc';
import { Form, FormType, UILayout, FormVersion } from '../types/System.types';
import { useCheckDocAccess } from '../use/data/useCheckDocAccess';

export interface FormsContextType {
    forms: ReturnType<typeof useBoundCollection<Form>>;
    formTypes: ReturnType<typeof useBoundCollection<FormType>>;
    selectedForm: ReturnType<typeof useBoundDoc<Form>>;
    selectedFormVersion: ReturnType<typeof useBoundDoc<FormVersion>>;
    formTypeOfSelectedForm: ReturnType<typeof useBoundDoc<FormType>>;
    selectedUILayout: ReturnType<typeof useBoundDoc<UILayout>>;
    formVersions: ReturnType<typeof useBoundCollection<FormVersion>>;
    uiLayouts: ReturnType<typeof useBoundCollection<UILayout>>;
    isLoading: boolean;
    selectForm: (formId: string | undefined) => void;
    selectFormVersion: (formVersionId: string | undefined) => void;
    selectUILayout: (uiLayoutId: string | undefined) => void;
    formVersionAccess: ReturnType<typeof useCheckDocAccess>;
    insideFormContext: boolean;
}

export const FormsContext = createContext<FormsContextType>({} as FormsContextType);

export const FormsProvider: FC<{
    children: ReactNode;
}> = ({ 
    children, 
}) => {
    const [isLoading, setIsLoading] = useState(true);
    const [selectedFormId, selectForm] = useState<string | undefined>(undefined);
    const [selectedFormVersionId, selectFormVersion] = useState<string | undefined>(undefined);
    const [selectedUILayoutId, selectUILayout] = useState<string | undefined>(undefined);

    const forms = useBoundCollection<Form>({
        path: 'forms',
        initialOrderBy: [{ field: 'meta.created', direction: 'desc' }],
        initialLimit: 50
    });

    const formTypes = useBoundCollection<FormType>({
        path: 'formTypes',
        initialOrderBy: [{ field: 'description.shortLabel', direction: 'asc' }],
        initialLimit: 50
    });

    const selectedForm = useBoundDoc<Form>({
        path: 'forms',
        docId: selectedFormId,
        enabled: !!selectedFormId
    });

    const formVersions = useBoundCollection<FormVersion>({
        path: `forms/${selectedFormId}/formVersions`,
        enabled: !!selectedFormId
    });

    const selectedFormVersion = useBoundDoc<FormVersion>({
        path: `forms/${selectedFormId}/formVersions`,
        docId: selectedFormVersionId,
        enabled: !!selectedFormId && !!selectedFormVersionId
    });

    const formTypeOfSelectedForm = useBoundDoc<FormType>({
        path: 'formTypes',
        docId: selectedForm.data?.formType,
        enabled: !!selectedForm.data?.formType
    });

    const selectedUILayout = useBoundDoc<UILayout>({
        path: `formTypes/${selectedForm.data?.formType}/uiLayouts`,
        docId: selectedUILayoutId,
        enabled: !!selectedForm.data?.formType && !!selectedUILayoutId
    });

    const uiLayouts = useBoundCollection<UILayout>({
        path: `formTypes/${selectedForm.data?.formType}/uiLayouts`,
        enabled: !!selectedForm.data?.formType
    });

    const formVersionAccess = useCheckDocAccess(
        (!selectedForm.loading && !selectedFormVersion.loading && selectedForm.data && selectedFormVersionId)
            ? `forms/${selectedForm.data.docId}/formVersions`
            : null,
        (!selectedForm.loading && !selectedFormVersion.loading && selectedFormVersionId) || null
    );

    useEffect(() => {
        const loadingStates = [
            forms.loading,
            formTypes.loading,
            selectedForm.loading,
            selectedFormVersion.loading,
            formTypeOfSelectedForm.loading,
            selectedUILayout.loading,
            formVersions.loading,
            uiLayouts.loading,
            formVersionAccess.loading
        ];
        setIsLoading(loadingStates.some(state => state));
    }, [
        forms.loading, 
        formTypes.loading, 
        selectedForm.loading, 
        selectedFormVersion.loading, 
        formTypeOfSelectedForm.loading, 
        selectedUILayout.loading, 
        formVersions.loading, 
        uiLayouts.loading, 
        formVersionAccess.loading
    ]);

    const memoizedForms = useMemo(() => forms, [forms]);
    const memoizedFormTypes = useMemo(() => formTypes, [formTypes]);
    const memoizedSelectedForm = useMemo(() => selectedForm, [selectedForm]);
    const memoizedSelectedFormVersion = useMemo(() => selectedFormVersion, [selectedFormVersion]);
    const memoizedFormTypeOfSelectedForm = useMemo(() => formTypeOfSelectedForm, [formTypeOfSelectedForm]);
    const memoizedSelectedUILayout = useMemo(() => selectedUILayout, [selectedUILayout]);
    const memoizedFormVersions = useMemo(() => formVersions, [formVersions]);
    const memoizedUILayouts = useMemo(() => uiLayouts, [uiLayouts]);
    const memoizedFormVersionAccess = useMemo(() => formVersionAccess, [formVersionAccess]);

    const memoizedSelectForm = useCallback((formId: string | undefined) => selectForm(formId), []);
    const memoizedSelectFormVersion = useCallback((formVersionId: string | undefined) => selectFormVersion(formVersionId), []);
    const memoizedSelectUILayout = useCallback((uiLayoutId: string | undefined) => selectUILayout(uiLayoutId), []);

    const value = useMemo(() => ({
        forms: memoizedForms,
        formTypes: memoizedFormTypes,
        selectedForm: memoizedSelectedForm,
        selectedFormVersion: memoizedSelectedFormVersion,
        formTypeOfSelectedForm: memoizedFormTypeOfSelectedForm,
        selectedUILayout: memoizedSelectedUILayout,
        formVersions: memoizedFormVersions,
        uiLayouts: memoizedUILayouts,
        selectForm: memoizedSelectForm,
        selectFormVersion: memoizedSelectFormVersion,
        selectUILayout: memoizedSelectUILayout,
        isLoading,
        formVersionAccess: memoizedFormVersionAccess,
        insideFormContext: true
    }), [
        memoizedForms, 
        memoizedFormTypes, 
        memoizedSelectedForm, 
        memoizedSelectedFormVersion, 
        memoizedFormTypeOfSelectedForm, 
        memoizedSelectedUILayout, 
        memoizedFormVersions, 
        memoizedUILayouts, 
        memoizedSelectForm, 
        memoizedSelectFormVersion, 
        memoizedSelectUILayout, 
        isLoading, 
        memoizedFormVersionAccess
    ]);
    
    if (isLoading) {
        return <Spin size="large" fullscreen/>;
    }

    return (
        <FormsContext.Provider value={value}>
            {children}
        </FormsContext.Provider>
    );
};