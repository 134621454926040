import { FC, useState, useContext, useCallback } from 'react';
import { Collapse, CollapseProps, Typography, Tag, Alert } from 'antd';
import { Fields, UIElement, EntityRecord, FormVersion, UIElementType, AgentType, Field } from '../../types/System.types';
import { renderByValueType } from './renderByValueType';
import { UserContext } from '../../providers/UserProvider';
import { EntityContext } from '../../providers/EntityProvider';
import FlexBox from '../atoms/FlexBox';
import { Timestamp } from 'firebase/firestore';
import { FormsContext } from '../../providers/FormsProvider';

interface UnmappedFieldsUIProps {
  itemData: EntityRecord | FormVersion;
  uiElement: UIElement;
}

const UnmappedFieldsUI: FC<UnmappedFieldsUIProps> = ({ uiElement, itemData }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const { user } = useContext(UserContext);
  const { 
    insideFormContext, 
    formVersionAccess, 
    selectedFormVersion 
  } = useContext(FormsContext);
  const { 
    insideEntityContext, 
    readOnly: entityReadOnly, 
    debouncedSetRecord
  } = useContext(EntityContext);

  const getDisplayedFields = (element: UIElement): string[] => {
    if (element.uiElementType === UIElementType.Field) {
      return [element.fieldId];
    } else if (element.uiElementType === UIElementType.EntityReference) {
      return [];
    } else if ('children' in element) {
      return element.children.flatMap(getDisplayedFields);
    }
    return [];
  };

  const displayedFields = getDisplayedFields(uiElement);
  const fields: Fields = 'fields' in itemData ? itemData.fields : {};
  const extraFields = Object.entries(fields).filter(
    ([fieldName]) => !displayedFields.includes(fieldName)
  );

  const handleFieldChange = useCallback((fieldName: string, value: any) => {
    const existingField = fields[fieldName] as Field | undefined;
    
    const updateObject = {
      fields: { 
        [fieldName]: { 
          value: value,
          fieldTypeId: existingField?.fieldTypeId || 'text',
          meta: {
            lastModified: Timestamp.now(),
            userId: user?.uid,
            agentType: AgentType.User,
          }
        } 
      } 
    };

    if (insideEntityContext) {
      debouncedSetRecord(updateObject);
    } else if (insideFormContext && formVersionAccess?.canWrite) {
      selectedFormVersion.debouncedSet(updateObject);
    }
  }, [insideEntityContext, debouncedSetRecord, insideFormContext, formVersionAccess, selectedFormVersion, fields, user?.uid]); // Updated dependency array

  const isReadOnly = insideEntityContext 
    ? entityReadOnly 
    : !formVersionAccess?.canWrite;

  if (!insideEntityContext && !insideFormContext) {
    return <Alert message="Error: Neither entity nor form context is available." type="error" />;
  }

  if (extraFields.length === 0) {
    return null;
  }

  const items: CollapseProps['items'] = [
    {
      key: '1',
      label: (
        <FlexBox>
          <span>
            Additional Fields
          </span>
          <Tag style={{ margin: 0 }}>
            {extraFields.length} fields
          </Tag>
        </FlexBox>
      ),
      children: extraFields.map(([fieldName, field]) => (
        <FlexBox
          key={fieldName}
          column
          justifyStart
          alignStart
          gap={0}
          stretch
          style={{
            marginBottom: 10,
          }}
        >
          <Typography.Text>{fieldName}</Typography.Text>
          {renderByValueType(
            field.value,
            fieldName,
            !isReadOnly,
            (value) => handleFieldChange(fieldName, value)
          )}
        </FlexBox>
      ))
    }
  ];

  return (
    <Collapse
      style={{ 
        marginTop: 10,
        width: '100%',
      }}
      bordered={false}
      items={items}
      activeKey={isExpanded ? ['1'] : []}
      onChange={() => setIsExpanded(!isExpanded)}
      size='small'
    />
  );
};

export default UnmappedFieldsUI;