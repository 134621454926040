import { 
    FC,
    useContext
} from 'react';
import { 
    Table,
    Typography,
    List,
    Grid
} from 'antd';
import { Timestamp } from 'firebase/firestore';
import type { ColumnsType } from 'antd/es/table';
import { useNavigate } from 'react-router-dom';
import { PaginationConfig } from 'antd/es/pagination';

import { Form } from '../../types/System.types';
import FlexCard from '../atoms/FlexCard';
import { UseTableResult } from '../../use/data/useTable';

const { useBreakpoint } = Grid;
const { Title, Text } = Typography;

const columns: ColumnsType<Form> = [
    {
        title: 'Title',
        dataIndex: 'title',
        key: 'title',
        width: '30%',
    },
    {
        title: 'Form Number',
        dataIndex: 'formNumber',
        key: 'formNumber',
        width: '20%',
    },
    {
        title: 'Form Type',
        dataIndex: 'formType',
        key: 'formType',
        width: '20%',
    },
    {
        title: 'Created',
        dataIndex: ['meta', 'created'],
        key: 'created',
        width: '30%',
        render: (timestamp: Timestamp) => {
            if (timestamp instanceof Timestamp) 
                return timestamp.toDate().toLocaleString();
            return 'Invalid Date';
        },
    },
];

interface FormsTableProps {
    tableProps: UseTableResult<Form>;
}

const FormsTable: FC<FormsTableProps> = ({ tableProps }) => {
    const navigate = useNavigate();
    const { md } = useBreakpoint();

    const {
        tableData,
        listPagination,
        tablePagination,
        tableLoading,
        tableError,
    } = tableProps;

    if (tableError) {
        return <Text type="danger">Error: {tableError.message}</Text>;
    }
    
    if (!md) {
        return (
            <List
                dataSource={tableData}
                loading={tableLoading}
                renderItem={(item: Form) => (
                    <FlexCard 
                        key={item.docId}
                        alignStart
                        hoverable
                        onClick={() => navigate(`/forms/${item.docId}`)}
                        style={{ marginBottom: 16, cursor: 'pointer' }}
                    >
                        <Title 
                            level={5}
                            style={{ margin: 0 }}
                        >
                            {item.title}
                        </Title>
                        <Text>
                            Form Number: {item.formNumber}
                        </Text>
                        <Text>
                            Form Type: {item.formType}
                        </Text>
                        <Text>
                            Created: {
                                item.meta?.created instanceof Timestamp 
                                    ? item.meta.created.toDate().toLocaleString() 
                                    : 'Invalid Date'
                            }
                        </Text>
                    </FlexCard>
                )}
                pagination={listPagination as PaginationConfig}
            />
        );
    }

    return (
        <Table
            style={{
                width: '100%',
            }}
            dataSource={tableData}
            columns={columns}
            loading={tableLoading}
            rowKey="docId"
            pagination={tablePagination}
            onRow={(record) => ({
                onClick: () => navigate(`/forms/${record.docId}`),
                style: { cursor: 'pointer' },
            })}
            scroll={{ x: 'max-content' }}
        />
    );
};

export default FormsTable;