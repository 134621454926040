import { useState, useCallback, useMemo, useEffect } from 'react';
import { useBoundCollection } from './useBoundCollection';
import { ListOrderBy, ListFilters } from '../../types/Data.types';
import { DocDataWithId } from '../../types/System.types';
import { TablePaginationConfig } from 'antd/es/table';
import { PaginationConfig } from 'antd/es/pagination';
import { debounce } from 'lodash';

interface BasePagination {
    current: number;
    pageSize: number;
    total: number;
    onChange: (page: number, pageSize: number) => void;
}

export interface UseTableResult<T extends DocDataWithId> {
    tableData: T[];
    listPagination: BasePagination;
    tablePagination: TablePaginationConfig;
    tableLoading: boolean;
    tableError: Error | null;
    setPage: (page: number, pageSize?: number) => void;
    setTableOrderBy: (orderBy: ListOrderBy) => void;
    refreshTable: () => void;
    setFilters: (filters: ListFilters) => void;
    setOrderBy: (orderBy: ListOrderBy) => void;
}

interface UseTableParams {
    path: string;
    initialPageSize?: number;
    initialOrderBy?: ListOrderBy;
    initialPage?: number;
}

export function useTable<T extends DocDataWithId>({
    path,
    initialPage = 1,
    initialPageSize = 10,
    initialOrderBy = [{ field: 'meta.created', direction: 'desc' }],
}: UseTableParams): UseTableResult<T> {
    const [currentPage, setCurrentPage] = useState(initialPage);
    const [pageSize, setPageSize] = useState(initialPageSize);
    const [orderBy, setInternalOrderBy] = useState<ListOrderBy>(initialOrderBy);
    const [localData, setLocalData] = useState<T[]>([]);

    const {
        docs,
        data,
        error,
        loading,
        refresh,
        setLimit,
        setStartAt,
        setFilters: setBoundFilters,
        setOrderBy: setBoundOrderBy,
    } = useBoundCollection<T>({
        path,
        initialLimit: 50,
        initialOrderBy: orderBy,
    });

    useEffect(() => {
        setLocalData(prevData => [...prevData, ...data]);
    }, [data]);

    const debouncedSetStartAt = debounce((startAtDoc: T | undefined) => {
        setStartAt(startAtDoc);
    }, 300);

    const setPage = useCallback((page: number, newPageSize?: number) => {
        const updatedPageSize = newPageSize || pageSize;
        setCurrentPage(page);
        setPageSize(updatedPageSize);

        const startIndex = (page - 1) * updatedPageSize;
        if (startIndex >= localData.length - updatedPageSize) {
            const startAtDoc = localData[localData.length - 1];
            debouncedSetStartAt(startAtDoc);
        }
    }, [localData, pageSize, debouncedSetStartAt]);

    const setTableOrderBy = useCallback((newOrderBy: ListOrderBy) => {
        setInternalOrderBy(newOrderBy);
        setCurrentPage(1);
        setStartAt(undefined);
        setLocalData([]);
    }, [setStartAt]);

    const basePagination: BasePagination = useMemo(() => ({
        current: currentPage,
        pageSize: pageSize,
        total: localData.length + (loading ? pageSize : 0),
        onChange: setPage,
    }), [currentPage, pageSize, localData.length, loading, setPage]);

    const tablePagination: TablePaginationConfig = useMemo(() => ({
        ...basePagination,
        showSizeChanger: true,
        showQuickJumper: true,
    }), [basePagination]);

    const tableData = useMemo(() => {
        const startIndex = (currentPage - 1) * pageSize;
        return localData.slice(startIndex, startIndex + pageSize);
    }, [localData, currentPage, pageSize]);

    useEffect(() => {
        console.log(
            `Data Length: ${localData.length} ` +
            `Current Page: ${currentPage} ` +
            `Page Size: ${pageSize} ` +
            `Total: ${basePagination.total}`
        );
    }, [localData.length, currentPage, pageSize, basePagination.total]);

    const setFilters = useCallback((filters: ListFilters) => {
        setBoundFilters(filters);
        setCurrentPage(1);
        setLocalData([]);
    }, [setBoundFilters]);

    const setOrderBy = useCallback((newOrderBy: ListOrderBy) => {
        setBoundOrderBy(newOrderBy);
        setCurrentPage(1);
        setLocalData([]);
    }, [setBoundOrderBy]);

    return {
        tableData,
        listPagination: basePagination,
        tablePagination,
        tableLoading: loading,
        tableError: error,
        setPage,
        setTableOrderBy,
        refreshTable: refresh,
        setFilters,
        setOrderBy,
    };
}