import { FC, useContext, useState } from 'react';
import { Breadcrumb, Dropdown, Button, Typography, Tooltip, Alert } from 'antd';
import { DownOutlined, FilePdfOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { FormsContext } from '../../providers/FormsProvider';
import { createFormVersion, createFormFromForm } from '../../services/artifactFunctions';
import FlexCard from '../atoms/FlexCard';
import FlexBox from '../atoms/FlexBox';
import DocumentAccess from '../atoms/DocumentAccess';
import { FormStateId, TimeType, FormTypeId, formTypeDescription, formStateDescription } from '../../types/System.types';
import { pdfExport } from '../../services/pdfExport';

const { Text } = Typography;

interface FormControlProps {
  formId: string;
  contentRef: React.RefObject<HTMLDivElement>;
}

const FormControl: FC<FormControlProps> = ({ formId, contentRef }) => {
  const {
    selectedForm,
    selectedFormVersion,
    formVersions,
    selectFormVersion,
    formTypeOfSelectedForm,
    formVersionAccess
  } = useContext(FormsContext);

  const navigate = useNavigate();
  const [error, setError] = useState<string | null>(null);
  const [isCreating, setIsCreating] = useState(false);

  const handleVersionChange = (versionId: string) => {
    selectFormVersion(versionId);
  };

  const handleCreateVersion = async (state: FormStateId) => {
    if (!selectedFormVersion.data) return;

    setIsCreating(true);
    setError(null);

    try {
      const newVersionId = await createFormVersion(formId, state, selectedFormVersion.data);
      selectFormVersion(newVersionId);
    } catch (err) {
      setError(`Error creating new version: ${err instanceof Error ? err.message : String(err)}`);
    } finally {
      setIsCreating(false);
    }
  };

  const handleCreateFormFromForm = async (newFormType: FormTypeId) => {
    if (!selectedFormVersion.data) return;

    setIsCreating(true);
    setError(null);

    try {
      const newFormId = await createFormFromForm({
        formId,
        newFormType,
        title: `New ${formTypeDescription[newFormType]?.title} from ${selectedForm.data?.title || 'existing form'}`,
      });
      navigate(`/artifacts/forms/${newFormId}`);
    } catch (err) {
      setError(`Error creating new form: ${err instanceof Error ? err.message : String(err)}`);
    } finally {
      setIsCreating(false);
    }
  };

  const handlePrint = () => {
    pdfExport({
      contentRef,
      formData: selectedForm.data,
      versionTitle: selectedFormVersion.data?.docId,
    });
  };

  const formatDate = (time: TimeType | undefined) => {
    if (time && 'toDate' in time) {
      return time.toDate().toLocaleString();
    }
    return '';
  };

  const versionOptions = formVersions.data?.map(version => ({
    id: version.docId || '',
    title: version.docId || '',
    state: version.state,
    description: formStateDescription[version.state as FormStateId]?.description || '',
    created: formatDate(version.meta?.created)
  })) || [];

  const versionItems = versionOptions.map(option => ({
    key: option.id,
    label: (
      <FlexBox>
        <Tooltip title={option?.description || ''}>
          <Text><b>{option.state}</b> {option.title}</Text>
        </Tooltip>
        <Text type="secondary">{option.created}</Text>
      </FlexBox>
    ),
    onClick: () => handleVersionChange(option.id),
  }));

  const createItems = [
    {
      key: 'version',
      type: 'group' as const,
      label: 'New Version',
      children: formTypeOfSelectedForm.data?.possibleStates && selectedFormVersion.data
        ? formTypeOfSelectedForm.data.possibleStates[selectedFormVersion.data.state].allowedNextStates.map(state => ({
          key: `version-${state}`,
          label: state,
          onClick: () => handleCreateVersion(state as FormStateId)
        }))
        : []
    },
    {
      key: 'form',
      type: 'group' as const,
      label: 'New Form',
      children: formTypeOfSelectedForm.data?.allowedNextForms
        ? formTypeOfSelectedForm.data.allowedNextForms.map(formType => ({
          key: `form-${formType}`,
          label: formTypeDescription[formType]?.title,
          onClick: () => handleCreateFormFromForm(formType)
        }))
        : []
    }
  ];

  const currentState = selectedFormVersion.data?.state;
  const stateDescription = currentState ? formStateDescription[currentState] : formStateDescription['*'];

  return (
    <FlexCard
      row
      wrap
      alignCenter
      justifyCenter
      gap={10}
      style={{
        minWidth: '100%',
        marginBottom: 10,
      }}
    >
      <FlexBox noGrow>
        <Breadcrumb
          items={[
            {
              title: formId,
            },
            {
              title: (
                <Tooltip title={stateDescription?.description || ''}>
                  <Text><b>{stateDescription?.title}</b> {selectedFormVersion.data?.docId}</Text>
                </Tooltip>
              ),
              menu: { items: versionItems },
            }
          ]}
        />
        <DocumentAccess
          canRead={formVersionAccess?.canRead}
          canWrite={formVersionAccess?.canWrite}
        />
      </FlexBox>
      <FlexBox stretch justifyEnd gap={5}>
        <Button
          type='link'
          icon={<FilePdfOutlined />}
          onClick={handlePrint}
        >
          PDF
        </Button>
        <Dropdown 
          menu={{ items: createItems }} 
          trigger={['click']} 
          disabled={isCreating || !formVersionAccess?.canWrite}
        >
          <Button
            type="link"
            loading={isCreating}
            disabled={isCreating || !formVersionAccess?.canWrite}
          >
            Create New <DownOutlined />
          </Button>
        </Dropdown>
      </FlexBox>
      {error && 
        <Alert 
          message={error} 
          type="error" 
          closable 
          onClose={() => setError(null)} 
          style={{
            width: '100%',
          }}
        />
      }
    </FlexCard>
  );
};

export default FormControl;
