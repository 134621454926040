import { FC, useContext, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { Skeleton, Result, Button } from 'antd';
import Navb from '../components/Navb';
import FlexPage from '../components/atoms/FlexPage';
import FlexContent from '../components/atoms/FlexContent';
import FlexBox from '../components/atoms/FlexBox';
import { FormsProvider, FormsContext } from '../providers/FormsProvider';
import FormUI from '../components/rendering/FormUI';
import FormControl from '../components/artifacts/FormControl';

const FormPageContent: FC = () => {
	const { formId } = useParams<{ formId: string }>();
	const contentRef = useRef<HTMLDivElement>(null);

	const {
		forms,
		selectedForm,
		selectedFormVersion,
		formTypeOfSelectedForm,
		isLoading,
		selectForm,
		selectFormVersion,
	} = useContext(FormsContext);

	useEffect(() => {
		if (!isLoading) {
			selectForm(formId);
		}
	}, [isLoading]);

	useEffect(() => {
		if (selectedForm.data && selectedForm.data.currentVersionId) {
			selectFormVersion(selectedForm.data.currentVersionId);
		}
	}, [selectedForm.data, selectFormVersion]);

	if (isLoading || forms.loading || selectedForm.loading || selectedFormVersion.loading || formTypeOfSelectedForm.loading) {
		return <Skeleton active />;
	}

	if (selectedForm.error) {
		return (
			<Result
				status="404"
				title="Form Not Found"
				subTitle="The requested form does not exist or you don't have permission to view it."
				extra={<Button type="primary" onClick={() => window.history.back()}>Go Back</Button>}
			/>
		);
	}

	return (
		<FlexBox column alignStart justifyStart noGrow stretch>
			{formId &&
				<FormControl
					formId={formId}
					contentRef={contentRef}
				/>
			}
			{selectedForm.data && selectedFormVersion.data && formTypeOfSelectedForm.data?.defaultUILayout &&
				<FormUI contentRef={contentRef} />
			}
		</FlexBox>
	);
};

const FormPage: FC = () => {
	return (
		<FormsProvider>
			<FlexPage>
				<Navb />
				<FlexContent>
					<FormPageContent />
				</FlexContent>
			</FlexPage>
		</FormsProvider>
	);
};

export default FormPage;