import React, { useState, useContext } from 'react';
import {
    Button,
    Input,
    Select,
    Space,
    Dropdown,
    MenuProps,
    Tooltip,
    message
} from 'antd';
import {
    SearchOutlined,
    DownOutlined
} from '@ant-design/icons';
import { FormsContext } from '../../providers/FormsProvider';
import { WhereFilterOp } from 'firebase/firestore';
import { createForm } from '../../services/artifactFunctions';
import { useNavigate } from 'react-router-dom';
import FlexBox from '../atoms/FlexBox';
import { UseTableResult } from '../../use/data/useTable';
import { Form } from '../../types/System.types';
import { ListFilters, ListOrderBy } from '../../types/Data.types';

interface FormsTableControlsProps {
    tableProps: UseTableResult<Form>;
}

const FormsTableControls: React.FC<FormsTableControlsProps> = ({ tableProps }) => {
    const context = useContext(FormsContext);
    if (!context) throw new Error('FormsContext is undefined');
    const { formTypes } = context;

    const [filterInputs, setFilterInputs] = useState<{ title?: string; formType?: string }>({});
    const [sortBy, setSortBy] = useState<string>('meta.created');
    const navigate = useNavigate();

    const handleFilterChange = (key: 'title' | 'formType', value: string) => {
        const newFilters = { ...filterInputs, [key]: value };
        setFilterInputs(newFilters);

        const filters: ListFilters = Object.entries(newFilters)
            .filter(([_, val]) => val !== null && val !== '')
            .map(([key, val]) => ({
                field: key,
                operator: '==' as WhereFilterOp,
                value: val
            }));

        tableProps.setFilters(filters);
    };

    const handleSortChange = (value: string) => {
        setSortBy(value);
        const newOrderBy: ListOrderBy = [{ field: value, direction: 'desc' }];
        tableProps.setOrderBy(newOrderBy);
    };

    const formTypesList = Object.values(formTypes.data);

    const handleCreateForm = async (formTypeId: string) => {
        try {
            const selectedFormType = Object.values(formTypes.data).find(type => type.docId === formTypeId);
            const newFormId = await createForm({
                formType: formTypeId,
                title: `New ${selectedFormType?.description?.shortLabel || 'Form'}`,
            });
            message.success('New form created successfully');
            navigate(`/forms/${newFormId}`);
        } catch (error) {
            console.error('Error creating new form:', error);
            message.error('Failed to create new form');
        }
    };

    const createItems: MenuProps['items'] = Object.values(formTypes.data).map(formType => ({
        key: formType.docId!,
        label: (
            <Tooltip title={formType.description?.longDescription}>
                {formType.description?.longLabel || 'Unnamed Form Type'}
            </Tooltip>
        ),
        onClick: () => handleCreateForm(formType.docId!)
    }));

    return (
        <FlexBox noGrow stretch alignEnd>
            <Space wrap>
                {/* <Input
                    style={{ 
                        width: 200  ////// HIDE FOR NOW UNTIL FROM SEARCH INDEX IS READY
                    }}
                    placeholder="Search forms"
                    prefix={<SearchOutlined />}
                    value={filterInputs.title}
                    onChange={e => handleFilterChange('title', e.target.value)}
                    variant='filled'
                    allowClear={{ clearIcon: <CloseCircleOutlined /> }}
                /> */}
                <Select
                    value={filterInputs.formType ?? ''}
                    onChange={value => handleFilterChange('formType', value)}
                    style={{ width: 200 }}
                    options={[
                        { value: '', label: <em>All types</em> },
                        ...formTypesList.map((t) => ({
                            value: t.docId || '',
                            label: t.description?.longLabel || t.description?.shortLabel || 'Unnamed Form Type'
                        })) || []
                    ]}
                    variant='filled'
                />
                <Select
                    value={sortBy}
                    onChange={handleSortChange}
                    style={{ width: 200 }}
                    options={[
                        { value: 'title', label: 'Title' },
                        { value: 'formType', label: 'Form Type' },
                        { value: 'meta.created', label: 'Created Date' }
                    ]}
                    variant='filled'
                />
            </Space>
            <Dropdown
                menu={{ items: createItems }}
                trigger={['click']}
                disabled={formTypes.loading || formTypes?.data.length === 0}
            >
                <Button
                    disabled={formTypes.loading || formTypes?.data.length === 0}
                    type='link'
                >
                    Add
                    <DownOutlined />
                </Button>
            </Dropdown>
        </FlexBox>
    );
};

export default FormsTableControls;