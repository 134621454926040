import { FC, useContext, useCallback, useEffect } from 'react';
import { Select, Skeleton, Alert } from 'antd';
import { EntityContext } from '../../providers/EntityProvider';
import { FormsContext } from '../../providers/FormsProvider';

const EntityRecordSelector: FC = () => {
  const {
    entityRecords,
    loading,
    error,
    selectEntityRecord,
    selectedEntity,
  } = useContext(EntityContext);

  const formsContext = useContext(FormsContext);
  const { 
    insideFormContext,
    formVersionAccess,
    selectedFormVersion,
  } = formsContext;

  const handleSelect = useCallback((recordId: string) => {
    selectEntityRecord(recordId);
    if (insideFormContext && selectedFormVersion && selectedEntity?.docId) {
      selectedFormVersion.debouncedSet({
        entityReferences: {
          ...selectedFormVersion.data?.entityReferences,
          [selectedEntity.docId]: recordId
        }
      });
    }
  }, [selectEntityRecord, insideFormContext, selectedEntity, selectedFormVersion]);

  if (loading.records) return <Skeleton active paragraph={{ rows: 1 }} />;
  if (error.records) return <Alert message={`Error: ${error.records?.message}`} type="error" />;

  const isDisabled = insideFormContext ? 
    !formVersionAccess?.canWrite 
    : false;

  return (
    <Select
      style={{ 
        width: '100%',
        marginBottom: 10,
      }}
      placeholder="Select an entity record"
      onChange={handleSelect}
      disabled={isDisabled}
    >
      {entityRecords.map((record) => (
        <Select.Option key={record.docId} value={record.docId}>
          {record.name}
        </Select.Option>
      ))}
    </Select>
  );
};

export default EntityRecordSelector;